import React from 'react'

function Prices() {
  return (<p>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
    Prices!<br/>
  </p>);
}

export default Prices;