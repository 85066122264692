import React from 'react';
import './Footer.css'

function Footer() {
  return (<div className="footer">
      <p>
        Copyright 2020 - Haris Mushtaq
      </p>
    </div>);
}

export default Footer;