import React from 'react'

function Accommodation() {
  return (<p>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
    Accommodation!<br/>
  </p>);
}

export default Accommodation;