import React from 'react'

function Summer() {
  return (<p>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
    Summer!<br/>
  </p>);
}

export default Summer;