import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './MainApp.css';
import Header from '../header/Header'
import ContentPanel from '../contentpanel/ContentPanel'
import Home from '../home/Home'
import Winter from '../winter/Winter'
import Summer from '../summer/Summer'
import SkiArea from '../skiarea/SkiArea'
import Prices from '../prices/Prices'
import Accommodation from '../accommodation/Accommodation'
import Contact from '../contact/Contact'
import Footer from '../footer/Footer'


function MainApp() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header/>
        <ContentPanel>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/winter" component={Winter} />
            <Route path="/summer" component={Summer} />
            <Route path="/skiarea" component={SkiArea} />
            <Route path="/prices" component={Prices} />
            <Route path="/accommodation" component={Accommodation} />
            <Route path="/contact" component={Contact} />
          </Switch>
        </ContentPanel>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default MainApp;
