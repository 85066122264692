import React from 'react'

function SkiArea() {
  return (<p>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
    SkiArea!<br/>
  </p>);
}

export default SkiArea;