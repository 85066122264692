import React, {useState} from 'react';
import UseDocumentScrollThrottled from '../../common/UseDocumentScrollThrottled'
import './ContentPanel.css';

function ContentPanel(props) {
  const [headerExpanded, setHeaderExpanded] = useState(false);
  const [headerShrunk, setHeaderShrunk] = useState(false);

  const MINIMUM_SCROLL = 120;
  const TIMEOUT_DELAY = 200;

  UseDocumentScrollThrottled(callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setHeaderShrunk(currentScrollTop > 2);

    setTimeout(() => {
      setHeaderExpanded(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  const expandedStyle = headerExpanded ? 'expanded' : '';
  const shrunkStyle = headerShrunk ? 'shrunk' : '';
  return (
    <div className={`contentpanel ${expandedStyle} ${shrunkStyle}`}>
      {props.children}
    </div>
  );
}

export default ContentPanel;