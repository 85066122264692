import React, {useState} from 'react'
import UseDocumentScrollThrottled from '../../common/UseDocumentScrollThrottled'
import NavBar from '../navbar/NavBar'
import './Header.css'
import Logo from '../../assets/hoch-logo.svg'

function Header() {
  const [headerExpanded, setHeaderExpanded] = useState(false);
  const [headerShrunk, setHeaderShrunk] = useState(false);

  const MINIMUM_SCROLL = 120;
  const TIMEOUT_DELAY = 200;

  UseDocumentScrollThrottled(callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setHeaderShrunk(currentScrollTop > 2);

    setTimeout(() => {
      setHeaderExpanded(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  const expandedStyle = headerExpanded ? 'expanded' : '';
  const shrunkStyle = headerShrunk ? 'shrunk' : '';
  return (
    <header className={`App-header ${expandedStyle} ${shrunkStyle}`}>
        <NavBar/>
        <div className="logo-container">
          <img className="logo" src={Logo} />
        </div>
        <div className="header-content-container">
          <h1>Winter and Summer</h1>
          <h5>Amazing mountains to quench your thurst of nature all year around. 130KM of slopes and beautiful wild off-piste runs with guaranteed snow all winter</h5>
        </div>
    </header>
  );
}

export default Header;